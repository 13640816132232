import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import history from '~/services/history';
import { isNullOrEmpty, stringClear } from '~/helpers/util';

import { userCreateSuccess, userCreateFailure, userReadSuccess, userReadFailure } from './actions';

export function* onCreate({ payload }) {
  try {
    const { name, phone, email, password, passwordConfirm, birth, agree, newsletter } = payload.data;

    const formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    formData.append('emailConfirm', email);
    formData.append('password', password);
    formData.append('passwordConfirm', passwordConfirm);
    formData.append('phone', stringClear(phone, "1234567890"));
    if (!isNullOrEmpty(stringClear(birth, "1234567890"))) formData.append('birth', moment(birth).format('YYYY/MM/DD'));
    formData.append('agree', agree ? 1 : 0);
    formData.append('newsletter', newsletter ? 1 : 0);

    const response = yield call(api.post, '/register', formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userCreateSuccess(data));

    toast.success(result.message);

    history.push('/');
  }
  catch (err) {
    toast.error(String(err));
    yield put(userCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload

    const response = yield call(api.get, `/users/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    yield put(userReadSuccess(result.data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(userReadFailure());
  }
}

export default all([
  takeLatest('@users/CREATE_REQUEST', onCreate),
  takeLatest('@users/READ_REQUEST', onRead),
]);
