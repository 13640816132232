import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { userResignCreateFailure } from './actions';
import { gameReadSuccess } from '~/store/modules/game/actions';
import { userReadRequest } from '~/store/modules/user/actions';

export function* onCreate({ payload }) {
  try {
    const { gameId, userId } = payload;

    const response = yield call(api.get, `/games/${gameId}/resign/${userId}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(gameReadSuccess(data));
    yield put(userReadRequest(userId));
  }
  catch (err) {
    toast.error(String(err));
    yield put(userResignCreateFailure());
  }
}

export default all([
  takeLatest('@userResigns/CREATE_REQUEST', onCreate),
]);
