export function gameTipIndexRequest(id, unix) {
  return {
    type: '@gameTips/INDEX_REQUEST',
    payload: { id, unix },
  };
}

export function gameTipIndexSuccess(data) {
  return {
    type: '@gameTips/INDEX_SUCCESS',
    payload: { data },
  };
}

export function gameTipIndexFailure() {
  return {
    type: '@gameTips/INDEX_FAILURE',
  };
}

export function gameTipCheckRequest(id, piece, place) {
  return {
    type: '@gameTips/CHECK_REQUEST',
    payload: { id, piece, place },
  };
}

export function gameTipCheckSuccess(data) {
  return {
    type: '@gameTips/CHECK_SUCCESS',
    payload: { data },
  };
}

export function gameTipCheckFailure() {
  return {
    type: '@gameTips/CHECK_FAILURE',
  };
}

export function gameTipReadRequest(gameId, tipId) {
  return {
    type: '@gameTips/READ_REQUEST',
    payload: { gameId, tipId },
  };
}

export function gameTipReadSuccess(data) {
  return {
    type: '@gameTips/READ_SUCCESS',
    payload: { data },
  };
}

export function gameTipReadFailure() {
  return {
    type: '@gameTips/READ_FAILURE',
  };
}