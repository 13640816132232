export function gameContentReadRequest(gameId, tipId) {
  return {
    type: '@gameContents/READ_REQUEST',
    payload: { gameId, tipId }
  };
}

export function gameContentReadSuccess(data) {
  return {
    type: '@gameContents/READ_SUCCESS',
    payload: { data },
  };
}

export function gameContentReadFailure() {
  return {
    type: '@gameContents/READ_FAILURE',
  };
}
