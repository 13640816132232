import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from 'antd';

import history from '~/services/history';

import { userReadRequest } from '~/store/modules/user/actions';

import { PlayCircleOutlined, PieChartOutlined, UsergroupAddOutlined, BarChartOutlined } from '@ant-design/icons';
import { Container, Item, UserContainer, UserInfo } from './styles';

export default function Menu({ breadcrumb, isOpen, showMenu }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const user = useSelector(store => store.user.registry);

  const [selectedMenu, setSelectedMenu] = useState();

  const menuItens = [
    { key: 1, title: 'Jogar', icon: (<PlayCircleOutlined />), url: '/games' },
    { key: 2, title: 'Eventos', icon: (<PieChartOutlined />), url: '/events' },
    { key: 3, title: 'Amigos', icon: (<UsergroupAddOutlined />), url: '/friends' },
    { key: 4, title: 'Estatísticas', icon: (<BarChartOutlined />), url: '/stats' },
  ];

  function menuClick(url) {
    if (isOpen) showMenu(!isOpen);

    history.push(url);
  }

  useEffect(() => {
    const menu = menuItens.find(a => a.breadcrumb === breadcrumb);
    if (menu) setSelectedMenu(menu.key);

    dispatch(userReadRequest(profile.id));
  }, [breadcrumb]);

  return (
    <Container
      selectedKeys={selectedMenu}
      mode="inline"
    >
      <UserContainer>
        <UserInfo>
          <Avatar size={60} />
          <div style={{ marginLeft: 7 }}>
            <h1>{profile.name.split(' ')[0]}</h1>
            <span>{user.points}</span>
          </div>
        </UserInfo>
      </UserContainer>

      {menuItens.map(m => {
        return (
          <Item key={m.key} icon={m.icon} onClick={() => menuClick(m.url)}>
            {m.title}
          </Item>
        )
      })}
    </Container>
  );
}
