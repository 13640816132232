import React from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Col, Tooltip } from 'antd';

import history from '~/services/history';

import { signFailure } from '~/store/modules/auth/actions';

import { Container, Content, MenuIcon, LogoffIcon } from './styles';

export default function Header({ isOpen, showMenu }) {
  const dispatch = useDispatch();

  return (
    <Container>
      <Content>
        {isMobile && (
          <Col xs={24}>
            <MenuIcon onClick={() => showMenu(!isOpen)} />
          </Col>
        )}

        <div></div>

        <Tooltip title="Desconectar conta">
          <LogoffIcon onClick={() => { if (window.confirm('Deseja realmente desconectar sua conta?')) { dispatch(signFailure()); history.push('/'); } }} />
        </Tooltip>
      </Content>
    </Container >
  );
}
