import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';

import { gameReadRequest } from '~/store/modules/game/actions';
import {
  gameTipCheckSuccess, gameTipCheckFailure, gameTipIndexRequest, gameTipIndexSuccess, gameTipIndexFailure,
  gameTipReadSuccess, gameTipReadFailure
} from './actions';

export function* onCheck({ payload }) {
  try {
    let { id, piece, place } = payload;

    const response = yield call(api.get, `/games/${id}/piece-place-check?piece=${piece}&place=${place}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(gameTipCheckSuccess(data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(gameTipCheckFailure());
  }
}

export function* onIndex({ payload }) {
  try {
    const { id, unix } = payload;

    if (!unix) unix = moment().unix();

    const response = yield call(api.get, `games/${id}/tips-count`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const count = result.data.line;

    yield put(gameTipIndexSuccess({ count, unix: moment().valueOf(), delay: moment().valueOf() - unix }));

    if (Number(result.data?.finished) === 1) yield put(gameReadRequest(id));
    else yield put(gameTipIndexRequest(id, unix));

  }
  catch (err) {
    yield put(gameTipIndexFailure());
    yield put(gameTipIndexRequest(payload?.id, payload?.unix));
  }
}

export function* onRead({ payload }) {
  try {
    const { gameId, tipId } = payload;

    const response = yield call(api.get, `games/${gameId}/tips/${tipId}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(gameTipReadSuccess(data));
  }
  catch (err) {
    yield put(gameTipReadFailure());
  }
}

export default all([
  takeLatest('@gameTips/CHECK_REQUEST', onCheck),
  takeLatest('@gameTips/INDEX_REQUEST', onIndex),
  takeLatest('@gameTips/READ_REQUEST', onRead)
]);
