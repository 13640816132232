import produce from 'immer';

const INITIAL_STATE = {
  list: [],
  loading: false,
  saving: false,
  registry: {},
  error: false
};

export default function room(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@rooms/INDEX_REQUEST':
    case '@rooms/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@rooms/CREATE_REQUEST':
    case '@rooms/UPDATE_REQUEST':
    case '@rooms/DELETE_REQUEST':
      return produce(state, draft => {
        draft.saving = true;
        draft.error = false;
      });

    case '@rooms/INDEX_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@rooms/CREATE_SUCCESS':
    case '@rooms/READ_SUCCESS':
    case '@rooms/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.saving = false;
        draft.registry = action.payload?.data;
      });

    case '@rooms/UPDATE_SUCCESS':
      return produce(state, draft => {
        draft.registry = action.payload.data;
      });

    case '@rooms/INDEX_FAILURE':
    case '@rooms/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case '@rooms/CREATE_FAILURE':
    case '@rooms/UPDATE_FAILURE':
    case '@rooms/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.saving = false;
        draft.error = true;
        draft.registry = {};
      });

    default:
      return state;
  }
}
