import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 75vh;
  border: 1px solid #ccc;
  border-radius: 4px;
  user-select: none;
  cursor: default;

  &.no-border {
    border: 0;
    text-align: center;
  }

  img {
    width: 100%;
    z-index: 555;
  }

  .piece-move {
    cursor: pointer;
  }

  .bg-white,.bg-black {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bg-white {
    background-color: #fffde0;
    border: 1px solid #fffde0;
    
    &.bg-move {
      background-color: #f2ff83;
      border: 1px solid #f2ff83;
    }
  }

  .bg-black {
    background-color: #0089aa;
    border: 1px solid #0089aa;

    &.bg-move {
      background-color: #d3d972;
      border: 1px solid #d3d972;
    }
  }

  .place-to-move {
    border: 1px solid #ee7000;
    -moz-box-shadow:    inset 0 0 25px #ff9000;
    -webkit-box-shadow: inset 0 0 25px #ff9000;
    box-shadow:         inset 0 0 25px #ff9000;
  }

  .place-info {
    display: flex;
    position: absolute;
    z-index: 444;
    width: 100%;
    max-width: 127px;
    height: 100%;
    max-height: 126px;
    justify-content: flex-start;
    padding: 3px;
    font-size: 12px;

    &.info-letter {
      justify-content: flex-end;
      align-items: flex-end;
      text-transform: lowercase;
    }
  }

  .place-selected {
    border: 1px solid #ff9000;
    background-color: #ff9000;
  }

  .place-checked {
    border: 1px solid #ff0000;
    -moz-box-shadow:    inset 0 0 25px #ff655a;
    -webkit-box-shadow: inset 0 0 25px #ff655a;
    box-shadow:         inset 0 0 25px #ff655a;
  }
`;

export const PlayerContent = styled.div`
  width: 100%;
  max-width: 77vh;
  user-select: none;
  cursor: default;
  padding: 10px 0;

  .player-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .player-details {
    display: flex;
    flex-direction: column;

    .user-info {
      span {
        margin-right: 7px;

        &.anticon {          
          color: #999;
        }
      }
    }

    .pieces-out {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      img {
        height: 3.5vw;
        max-height: 25px;
        margin-right: -5px;
      }

      span {
        margin-left: 7px;
      }
    }
  }

  .ant-avatar {
    margin-right: 8px;
  }
`;

export const MoveContainer = styled.div`
  width: 100%;
  user-select: none;
  cursor: default;
  padding: 1.2rem 1rem;

  button {
    margin-left: .5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  img {
    min-height: 1rem;
    max-height: 1.8rem;
    opacity: 0.9;
  }

  .move-information {
    display: flex;
    flex-direction: row;
    margin: .4rem 0;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: .4rem;
    }

    img, span {
      cursor: pointer;
    }
  }

  .move-selected {
    span {
      color: #999;
    }

    img {
      opacity: 0.8;
    }
  }
`;
