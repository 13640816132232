import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Checkbox } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import moment from 'moment';

import history from '~/services/history';
import { stringClear } from '~/helpers/util';

import { userCreateRequest } from '~/store/modules/user/actions';

import { Container, Content, Header, Body, FormItem, Button } from './styles';

import piece from '~/assets/images/9.png';

export default function SignUp() {
  const dispatch = useDispatch();

  const saving = useSelector(store => store.user.loading);

  const [form] = Form.useForm();

  function onFinish(values) {
    var isValid = true;

    const { agree } = values;

    if (!agree) {
      form.setFields([
        {
          name: 'agree',
          errors: ['Você precisa concordar com os termos'],
        },
      ]);
      isValid = false;
    }

    if (!isValid) return;

    dispatch(userCreateRequest(values));
  }

  return (
    <Container>
      <Header>
        <Content>
          <div className='logo' onClick={() => history.push('/')}>
            <img src={piece} />
            <span>WSChess</span>
          </div>

          <Link to="/">Voltar para login</Link>
        </Content>
      </Header>

      <Body>
        <Content className='content'>
          <Row gutter={24}>
            <Col flex='auto' xs={0} md={8} lg={12} className='column info'>
              <h2>Ficamos felizes em te ter aqui no WebStation Chess!</h2>

              <p>Criamos este ambiente exatamente para jogadores que querem desenvolver suas habilidades, quem quer
                aprender e aqueles que querem se divertir.</p>
              <p>Preencha os campos ao lado para poder ter acesso ao jogo.</p>
            </Col>

            <Col xs={24} md={16} lg={12} className='column'>
              <div className="form-container">
                <Form layout="vertical"
                  form={form}
                  onFinish={onFinish}
                >
                  <Row gutter={12} style={{ padding: '12px 0 12px 12px' }}>
                    <Col xs={24}>
                      <FormItem
                        name="name"
                        label="Nome"
                        rules={[{ required: true, message: 'Nome é obrigatório' }]}
                      >
                        <Input />
                      </FormItem>
                    </Col>

                    <Col xs={24}>
                      <FormItem
                        name="email"
                        label="E-mail"
                        rules={[{ required: true, message: 'E-mail é obrigatório' }]}
                      >
                        <Input type='email' />
                      </FormItem>
                    </Col>

                    <Col xs={24} sm={12}>
                      <FormItem
                        name="password"
                        label="Senha"
                        rules={[{ required: true, message: 'Senha é obrigatória' }]}
                      >
                        <Input type='password' />
                      </FormItem>
                    </Col>

                    <Col xs={24} sm={12}>
                      <FormItem
                        name="passwordConfirm"
                        label="Confirme senha"
                        rules={[{ required: true, message: 'Confirmação é obrigatório' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('As senhas não combinam'));
                          },
                        })]}
                        dependencies={['password']}
                      >
                        <Input type='password' />
                      </FormItem>
                    </Col>

                    <Col xs={24} sm={12}>
                      <FormItem
                        name="phone"
                        label="Telefone"
                        rules={[{ required: true, message: 'Telefone é obrigatório' }, () => ({
                          validator(_, value) {
                            if (!value) return Promise.resolve();

                            const phoneNumber = stringClear(value, '1234567890');
                            var validLen = phoneNumber.substring(2, 3) === '9' ? 11 : 10;
                            if (phoneNumber.length === validLen) return Promise.resolve();

                            return Promise.reject(new Error(`'${validLen === 11 ? 'Celular' : 'Telefone'} inválido'`));
                          },
                        })]}>
                        <MaskedInput mask="(00)000000000" type="tel" />
                      </FormItem>
                    </Col>

                    <Col xs={24} sm={12}>
                      <FormItem
                        name="birth"
                        label="Nascimento"
                        rules={[() => ({
                          validator(_, value) {
                            if (!value) return Promise.resolve();

                            const birth = stringClear(value, '1234567890');

                            if (birth.length === 0) return Promise.resolve();
                            if (birth.length !== 8 || !moment(value).isValid()) return Promise.reject(new Error('Data inválida'));

                            if (moment(value).unix() < moment().unix()) return Promise.resolve();

                            return Promise.reject(new Error('Data inválida'));
                          },
                        })]}>
                        <MaskedInput mask="00/00/0000" type="tel" />
                      </FormItem>
                    </Col>

                    <Col xs={24}>
                      <FormItem name="agree" onChange={e => form.setFieldsValue({ agree: e.target.checked })}>
                        <Checkbox>Confirmo estar de acordo com os <a href="#" target="_blank">termos de uso</a> e <a href="#" target="_blank">política de privacidade</a> da plataforma.</Checkbox>
                      </FormItem>
                    </Col>

                    <Col xs={24}>
                      <FormItem name="newsletter" onChange={e => form.setFieldsValue({ newsletter: e.target.checked })}>
                        <Checkbox>Autorizo o envio de newsletter e promoções da plataforma e seus parceiros.</Checkbox>
                      </FormItem>
                    </Col>

                    <Col xs={24}>
                      <Button htmlType='submit' type='primary' loading={saving}>Cadastrar</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Content>
      </Body>
    </Container >
  );
}
