import styled from 'styled-components';
import { Form, Input as _input, Button as _button } from 'antd';


export const Container = styled.div`
  background-color: #d2d6de;
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 10px;
`;

export const Input = styled(_input)`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
`;

export const Button = styled(_button)`
  margin-top: 15px;
  margin-bottom: 10px;
`;
