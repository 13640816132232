import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import game from './game/sagas';
import gameContent from './gameContent/sagas';
import gameTip from './gameTip/sagas';
import room from './room/sagas';
import user from './user/sagas';
import userResign from './userResign/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    game,
    gameContent,
    gameTip,
    room,
    user,
    userResign
  ]);
}
