export function roomIndexRequest(filters) {
  return {
    type: '@rooms/INDEX_REQUEST',
    payload: { filters },
  };
}

export function roomIndexSuccess(data) {
  return {
    type: '@rooms/INDEX_SUCCESS',
    payload: { data },
  };
}

export function roomIndexFailure() {
  return {
    type: '@rooms/INDEX_FAILURE',
  };
}

export function roomCreateRequest(userId) {
  return {
    type: '@rooms/CREATE_REQUEST',
    payload: { userId }
  };
}

export function roomCreateSuccess(data) {
  return {
    type: '@rooms/CREATE_SUCCESS',
    payload: { data },
  };
}

export function roomCreateFailure() {
  return {
    type: '@rooms/CREATE_FAILURE',
  };
}

export function roomReadRequest(roomId, userId) {
  return {
    type: '@rooms/READ_REQUEST',
    payload: { roomId, userId }
  };
}

export function roomReadSuccess(data) {
  return {
    type: '@rooms/READ_SUCCESS',
    payload: { data },
  };
}

export function roomReadFailure() {
  return {
    type: '@rooms/READ_FAILURE',
  };
}

export function roomUpdateRequest(roomId, userId) {
  return {
    type: '@rooms/UPDATE_REQUEST',
    payload: { roomId, userId }
  };
}

export function roomUpdateSuccess(data) {
  return {
    type: '@rooms/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function roomUpdateFailure() {
  return {
    type: '@rooms/UPDATE_FAILURE',
  };
}

export function roomDeleteRequest(roomId, userId) {
  return {
    type: '@rooms/DELETE_REQUEST',
    payload: { roomId, userId }
  };
}

export function roomDeleteSuccess(data) {
  return {
    type: '@rooms/DELETE_SUCCESS',
    payload: { data },
  };
}

export function roomDeleteFailure() {
  return {
    type: '@rooms/DELETE_FAILURE',
  };
}