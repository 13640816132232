export function userCreateRequest(data) {
  return {
    type: '@users/CREATE_REQUEST',
    payload: { data },
  };
}

export function userCreateSuccess(data) {
  return {
    type: '@users/CREATE_SUCCESS',
    payload: { data },
  };
}

export function userCreateFailure() {
  return {
    type: '@users/CREATE_FAILURE',
  };
}

export function userReadRequest(id) {
  return {
    type: '@users/READ_REQUEST',
    payload: { id }
  };
}

export function userReadSuccess(data) {
  return {
    type: '@users/READ_SUCCESS',
    payload: { data },
  };
}

export function userReadFailure() {
  return {
    type: '@users/READ_FAILURE',
  };
}