import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Form, Row, Col, Input } from 'antd';

import Modal from '~/components/Modal';

import { userReadRequest } from '~/store/modules/user/actions';

export default function Finished({ gameId, isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const user = useSelector(store => store.user.registry);
  const loading = useSelector(store => store.game.loading);
  const registry = useSelector(store => store.game.registry);

  let points = Number(registry.finished) === 1 ? registry.userWhitePointsFinished - registry.userWhitePoints : 0;
  if (points < 0) points = points * -1;

  useEffect(() => {
    dispatch(userReadRequest(profile.id));
  }, [registry]);

  return (
    <Modal
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={undefined}
      maskClosable={false}
      width={400}
      processing={loading}
      title={!registry ? 'Partida concluída' : Number(registry.winnerWhite) === 1 ? 'Brancas vencem' : Number(registry.winnerWhite) === 0 ? 'Pretas vencem' : 'Empate'}
    >
      <Row gutter={8}>
        <Col xs={11} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Avatar size={60} />
          <div className='info' style={{ alignItems: 'flex-end', marginLeft: 7, textAlign: 'right' }}>
            <span>{Number(registry.winnerWhite) === 1 ? '1' : Number(registry.winnerWhite) === 0 ? '0' : '½'}</span><br />
            <span>{registry.userWhitePointsFinished}</span>
          </div>
        </Col>

        <Col xs={2} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
          -
        </Col>

        <Col xs={11} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <div className='info' style={{ alignItems: 'flex-start', marginRight: 7 }}>
            <span>{Number(registry.winnerWhite) === 1 ? '0' : Number(registry.winnerWhite) === 0 ? '1' : '½'}</span><br />
            <span>{registry.userBlackPointsFinished}</span>
          </div>
          <Avatar size={60} />
        </Col>

        <Col xs={24} style={{ textAlign: 'center' }}>
          {points > 0 && (
            <span>Rate: <b>{points}</b> pontos</span>
          )}
        </Col>
      </Row>
    </Modal>
  );
}
