import { combineReducers } from 'redux';

import auth from './auth/reducer';
import game from './game/reducer';
import gameContent from './gameContent/reducer';
import gameTip from './gameTip/reducer';
import room from './room/reducer';
import user from './user/reducer';
import userResign from './userResign/reducer';

export default combineReducers({
  auth,
  game,
  gameContent,
  gameTip,
  room,
  user,
  userResign
});