import styled from 'styled-components';
import { Col } from 'antd';

export const Container = styled.div`
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
`;

export const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #f5f5f5;
  border: 1px solid #d0d0d0;
  border-radius: 14px;
  padding: 1rem;
  margin-bottom: 1rem;

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;

    svg {
      font-size: 20px;
      cursor: pointer;
    }

    .anticon {
      &.green {
        color: #0f0;
      }

      &.red {
        color: #f00;
      }
    }
  }
`;
