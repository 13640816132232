import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Breadcrumb } from 'antd';

import history from '~/services/history';

import Header from '~/components/Header';
import Menu from '~/components/Menu';

import { Container, Layout, Sider, Drawer } from './styles';

export default function DefaultLayout({ children, breadcrumb }) {
  const [collapsed, setCollapsed] = useState(isMobile ? true : false);
  const [visible, setVisible] = useState(false);

  function onCollapse(collapsed) {
    setCollapsed(collapsed);
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Container>
      <Layout>
        <Header showMenu={setVisible} isOpen={visible} style={{ zIndex: 2222 }} />

        {isMobile ? (
          <Drawer
            placement='left'
            closable={false}
            onClose={onClose}
            visible={visible}
            key='left'
            width={250}
          >
            <Menu breadcrumb={breadcrumb} showMenu={setVisible} isOpen={visible} />
          </Drawer>
        ) : (
          <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} style={{ marginTop: '-64px', zIndex: 3333 }} >
            <div className="logo">

            </div>

            <Menu breadcrumb={breadcrumb} />
          </Sider>
        )}

        <Layout.Content style={{ margin: '64px 0 0 0' }}>

          {breadcrumb && breadcrumb.length > 0 && (
            <Breadcrumb style={{ margin: '16px 0' }}>
              {breadcrumb.map(bc => {
                var sBc = bc[0];
                var uBc = bc[1];

                if (!Array.isArray(bc)) {
                  sBc = bc;
                  uBc = undefined;
                }

                return (
                  <Breadcrumb.Item style={{ cursor: !uBc ? 'default' : 'pointer' }} onClick={() => !uBc ? {} : history.push(uBc)} key={sBc}>{sBc}</Breadcrumb.Item>
                )
              })}
            </Breadcrumb>
          )}

          <div className={`site-layout-background ${isMobile ? 'is-mobile' : ''}`} style={{ padding: isMobile ? '16px 7px 16px 7px' : '7px', minHeight: 360 }}>
            {children}
          </div>
        </Layout.Content>
      </Layout>
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumb: PropTypes.array
};

DefaultLayout.DefaultLayout = {
  breadcrumb: []
}
