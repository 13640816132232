export function userResignCreateRequest(gameId, userId) {
  return {
    type: '@userResigns/CREATE_REQUEST',
    payload: { gameId, userId },
  };
}

export function userResignCreateSuccess(data) {
  return {
    type: '@userResigns/CREATE_SUCCESS',
    payload: { data },
  };
}

export function userResignCreateFailure() {
  return {
    type: '@userResigns/CREATE_FAILURE',
  };
}

export function userResignReadRequest(id) {
  return {
    type: '@userResigns/READ_REQUEST',
    payload: { id }
  };
}

export function userResignReadSuccess(data) {
  return {
    type: '@userResigns/READ_SUCCESS',
    payload: { data },
  };
}

export function userResignReadFailure() {
  return {
    type: '@userResigns/READ_FAILURE',
  };
}