import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Row, Col, Spin, Button, Tooltip } from 'antd';

import ModalFinished from './Finished';

import { gameCreateRequest, gameReadRequest, gameUpdateRequest } from '~/store/modules/game/actions';
import { gameContentReadRequest } from '~/store/modules/gameContent/actions';
import { gameTipCheckRequest, gameTipIndexRequest } from '~/store/modules/gameTip/actions';
import { roomUpdateFailure } from '~/store/modules/room/actions';
import { userResignCreateRequest } from '~/store/modules/userResign/actions';

import { SignalFilled, FlagFilled, ReloadOutlined, ArrowLeftOutlined, ArrowRightOutlined, PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons';
import { Container, Content, PlayerContent, MoveContainer } from './styles';

export default function Game(props) {
  const dispatch = useDispatch();

  const { id } = props.match.params;
  const black = Number(props?.match?.params.black) === 1 ? 1 : 0;

  const profile = useSelector(store => store.auth.profile);
  const token = useSelector(store => store.auth.token);
  const registry = useSelector(store => store.game.registry);
  const gameTable = useSelector(store => store.game.game);
  const piecesOut = useSelector(store => store.game.piecesOut);
  const pointBlack = useSelector(store => store.game.pointBlack);
  const pointWhite = useSelector(store => store.game.pointWhite);
  const lastMove = useSelector(store => store.game.lastMove);
  const moves = useSelector(store => store.gameTip.list);
  const ping = useSelector(store => store.gameTip.ping);
  const moveContent = useSelector(store => store.gameContent.content);
  const moveLoading = useSelector(store => store.gameContent.loading);

  const [from, setFrom] = useState();
  const [curPlace, setCurPlace] = useState(undefined);
  const [isVisibleFinished, setIsVisibleFinished] = useState(false);
  const [kingCheck, setKingCheck] = useState(undefined);
  const [source, setSource] = useState([]);
  const [moveList, setMoveList] = useState([]);
  const [moveIndex, setMoveIndex] = useState();

  function setPlaces(piece, place, possible) {
    setCurPlace(place);

    if (piece % 2 !== black) {
      if (piece > 0) {
        setFrom({ piece, place });
        dispatch(gameTipCheckRequest(id, piece, place));
      }
    }

    if (possible && from.piece % 2 !== black && Number(registry?.finished) !== 1 && (registry?.userWhiteId === profile.id || registry?.userBlackId === profile.id)) {
      dispatch(gameUpdateRequest(id, from.piece, from.place, place, token));
    }
  }

  function getMove(tipId) {
    setMoveIndex(tipId);
    dispatch(gameContentReadRequest(id, tipId));
  }

  function backTip() {
    if (moveList?.length > 0) {
      let index = Number(moveIndex);
      if (!moveIndex) index = Number(moveList[moveList.length - 1].id);

      const myIndex = index;
      let isFound = false;
      for (let i = 0; i < moveList.length; i++) {
        if (myIndex === Number(moveList[i].id)) {
          isFound = true;
          i = moveList.length + 1;
        }
        else {
          if (!isFound) index = Number(moveList[i].id);
        }
      }

      getMove(index);
    }
  }

  function forwardTip() {
    if (moveList?.length > 0) {
      let index = Number(moveIndex);
      if (!moveIndex) index = Number(moveList[moveList.length - 1].id);

      const myIndex = index;
      let isFound = false;
      for (let i = 0; i < moveList.length; i++) {
        if (myIndex === Number(moveList[i].id)) isFound = true;
        else {
          if (isFound) {
            if (moveList.length < (i + 1)) index = Number(moveList[moveList.length - 1].id);
            else index = Number(moveList[i].id);

            i = moveList.length + 1;
          }
        }
      }

      getMove(index);
    }
  }

  useEffect(() => {
    if (id && id !== '') {
      dispatch(gameReadRequest(id));
      dispatch(gameTipIndexRequest(id, ping?.unix));
    }
    else dispatch(gameCreateRequest());
  }, []);

  useMemo(() => {
    dispatch(gameReadRequest(id));
  }, [ping?.count]);

  useMemo(() => {
    setCurPlace(undefined);
    setMoveIndex(undefined);

    const moves = registry?.Moves?.map((m, i) => {
      var movePieceImage = require(`~/assets/images/0.png`);
      try {
        movePieceImage = require(`~/assets/images/${m.piece}.png`);
      }
      catch (err) {
        //
      }

      let item = {};

      item.id = m.id;
      item.move = m;
      item.jsx = (<>
        {m.piece % 2 === 1 && <span style={{ marginLeft: 0, width: '1.8rem' }}>{m.line}.</span>}
        <img src={movePieceImage} key={i} />
        <span>{m.placePrevious}{Number(m.pieceAte) > 0 ? 'x' : '-'}{m.place}</span>
        {Number(m.check) === 1 ? <span>+</span> : <></>}
      </>);

      const lines = registry?.Moves?.filter(a => a.line === m.line && a.piece % 2 === m.piece % 2);

      if (lines?.length === 2) {
        if (m.placePrevious !== lines[0].placePrevious) item = undefined;

        if (m.placePrevious !== lines[1].placePrevious) {
          item.id = lines[1].id;

          var movePieceImage = require(`~/assets/images/0.png`);
          try {
            movePieceImage = require(`~/assets/images/${lines[0].piece}.png`);
          }
          catch (err) {
            //
          }

          if (Number(lines[0].piece) === 1 && lines[0].placePrevious === 'E1' && lines[0].place === 'G1') {
            item.id = lines[1].id;
            item.jsx = (<>
              {m.piece % 2 === 1 && <span style={{ marginLeft: 0, width: '1.8rem' }}>{m.line}.</span>}
              <img src={movePieceImage} key={i} />
              <span>O-O</span>
              {Number(m.check) === 1 ? <span>+</span> : <></>}
            </>);
          }
          if (Number(lines[0].piece) === 1 && lines[0].placePrevious === 'E1' && lines[0].place === 'C1') {
            item.id = lines[1].id;
            item.jsx = (<>
              {m.piece % 2 === 1 && <span style={{ marginLeft: 0, width: '1.8rem' }}>{m.line}.</span>}
              <img src={movePieceImage} key={i} />
              <span>O-O-O</span>
              {Number(m.check) === 1 ? <span>+</span> : <></>}
            </>);
          }
          if (Number(lines[0].piece) === 2 && lines[0].placePrevious === 'E8' && lines[0].place === 'G8') {
            item.id = lines[1].id;
            item.jsx = (<>
              <img src={movePieceImage} key={i} />
              <span>O-O</span>
              {Number(m.check) === 1 ? <span>+</span> : <></>}
            </>);
          }
          if (Number(lines[0].piece) === 2 && lines[0].placePrevious === 'E8' && lines[0].place === 'C8') {
            item.id = lines[1].id;
            item.jsx = (<>
              <img src={movePieceImage} key={i} />
              <span>O-O-O</span>
              {Number(m.check) === 1 ? <span>+</span> : <></>}
            </>);
          }
        }
      }

      if (item?.jsx) {
        return item;
      }
    });
    setMoveList(moves);

    if (registry?.LastMove?.check === 1 || registry?.LastMove?.check === '1') {
      setKingCheck(JSON.parse(registry.LastMove.content));
    }
    else setKingCheck(undefined);

    if (Number(registry?.finished) === 1) {
      setIsVisibleFinished(true);
      dispatch(roomUpdateFailure());
    }
  }, [registry]);

  useMemo(() => {
    if (moveContent?.length > 0) {
      setSource(moveContent);
    }
  }, [moveContent])

  useMemo(() => {
    setSource(gameTable);
  }, [gameTable])

  return (
    <Container>
      <Row>
        {source.length <= 0 && (
          <Col xs={24} style={{ textAlign: 'center' }}>
            <Spin />
          </Col>
        )}

        <Col xs={24} md={18} lg={12}>
          {source.length > 0 && (
            <PlayerContent>
              <Row>
                <Avatar size={40} />
                <div className='player-details'>
                  <span>{black ? registry.userNameWhite : registry.userNameBlack} ({black ? registry.userWhitePoints : registry.userBlackPoints})</span>
                  <div className='pieces-out'>
                    {piecesOut.filter(p => p.piece % 2 === black ? 0 : 1).map((p, i) => (
                      <img src={p.image} key={i} />
                    ))}
                    <span>{!black ? pointWhite > pointBlack ? `+${pointWhite - pointBlack}` : '' : pointWhite < pointBlack ? `+${pointBlack - pointWhite}` : ''}</span>
                  </div>
                </div>
              </Row>
            </PlayerContent>
          )}

          {source && (
            <Content className={[source.length <= 0 && 'no-border']} style={{ opacity: (moveLoading || moveContent) && Number(registry?.finished) !== 1 ? 0.8 : 1 }}>
              <Row>
                {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(56, 8).map((e, i) => {
                  const exists = moves.find(a => a === e.place);
                  return (
                    <>
                      {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                      <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 === 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected', e.piece === kingCheck?.king && e.place === kingCheck?.place && 'place-checked']}>
                        <img src={e.image} />
                      </Col>
                    </>
                  )
                })}
              </Row>
              <Row>
                {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(48, 8).map((e, i) => {
                  const exists = moves.find(a => a === e.place);
                  return (
                    <>
                      {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                      <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 !== 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected', e.piece === kingCheck?.king && e.place === kingCheck?.place && 'place-checked']}>
                        <img src={e.image} />
                      </Col>
                    </>
                  )
                })}
              </Row>
              <Row>
                {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(40, 8).map((e, i) => {
                  const exists = moves.find(a => a === e.place);
                  return (
                    <>
                      {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                      <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 === 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected', e.piece === kingCheck?.king && e.place === kingCheck?.place && 'place-checked']}>
                        <img src={e.image} />
                      </Col>
                    </>
                  )
                })}
              </Row>
              <Row>
                {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(32, 8).map((e, i) => {
                  const exists = moves.find(a => a === e.place);
                  return (
                    <>
                      {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                      <Col key={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 !== 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected', e.piece === kingCheck?.king && e.place === kingCheck?.place && 'place-checked']}>
                        <img src={e.image} />
                      </Col>
                    </>
                  )
                })}
              </Row>
              <Row>
                {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(24, 8).map((e, i) => {
                  const exists = moves.find(a => a === e.place);
                  return (
                    <>
                      {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                      <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 === 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected', e.piece === kingCheck?.king && e.place === kingCheck?.place && 'place-checked']}>
                        <img src={e.image} />
                      </Col>
                    </>
                  )
                })}
              </Row>
              <Row>
                {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(16, 8).map((e, i) => {
                  const exists = moves.find(a => a === e.place);
                  return (
                    <>
                      {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                      <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 !== 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected', e.piece === kingCheck?.king && e.place === kingCheck?.place && 'place-checked']}>
                        <img src={e.image} />
                      </Col>
                    </>
                  )
                })}
              </Row>
              <Row>
                {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(8, 8).map((e, i) => {
                  const exists = moves.find(a => a === e.place);
                  return (
                    <>
                      {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                      <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 === 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected', e.piece === kingCheck?.king && e.place === kingCheck?.place && 'place-checked']}>
                        <img src={e.image} />
                      </Col>
                    </>
                  )
                })}
              </Row>
              <Row>
                {(!black ? source.slice(0) : source.slice(0).reverse())?.splice(0, 8).map((e, i) => {
                  const exists = moves.find(a => a === e.place);
                  return (
                    <>
                      {i === 0 && <span className='place-info'>{e.place.substring(1, 2)}</span>}
                      <Col key={e.place} data-place={e.place} xs={3} onClick={() => setPlaces(e.piece, e.place, exists)} className={[i % 2 !== 0 ? 'bg-white' : 'bg-black', ((e.piece > 0 && e.type === black) || exists) && 'piece-move', (e.place === lastMove?.place || e.place === lastMove?.placePrevious) && 'bg-move', exists && 'place-to-move', (e.place === curPlace && e.piece > 0 && e.type === black) && 'place-selected', e.piece === kingCheck?.king && e.place === kingCheck?.place && 'place-checked']}>
                        <img src={e.image} />
                        <span className='place-info info-letter'>{e.place.substring(0, 1)}</span>
                      </Col>
                    </>
                  )
                })}
              </Row>
            </Content>
          )}

          {source.length > 0 && (
            <PlayerContent>
              <Row>
                <Col xs={24} className='player-info'>
                  <Avatar size={40} />
                  <div className='player-details'>
                    <div className='user-info'>
                      <span>{!black ? registry.userNameWhite : registry.userNameBlack}  ({!black ? registry.userWhitePoints : registry.userBlackPoints})</span>
                      <SignalFilled delay={Number(ping.delay)} title={`${ping.delay} ms`} style={{ color: ping?.delay < 400 ? '#0f0' : ping?.delay < 1000 ? '#fc0' : '#f00' }} />
                    </div>
                    <div className='pieces-out'>
                      {piecesOut.filter(p => p.piece % 2 === black ? 1 : 0).map((p, i) => (
                        <img src={p.image} key={i} />
                      ))}
                      <span>{black ? pointWhite > pointBlack ? `+${pointWhite - pointBlack}` : '' : pointWhite < pointBlack ? `+${pointBlack - pointWhite}` : ''}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </PlayerContent>
          )}
        </Col>

        <Col xs={24} md={6} lg={12}>
          <MoveContainer>
            <Row>
              <Col xs={24}>
                {source?.length > 0 && (registry?.finished === 0 || registry?.finished === '0') && (
                  <Tooltip title="Abandonar a partida?">
                    <Button
                      type='primary'
                      danger icon={<FlagFilled />}
                      onClick={() => { if (window.confirm('Deseja realmente desistir desta partida?')) dispatch(userResignCreateRequest(registry?.id, profile.id)) }}
                    />
                  </Tooltip>
                )}

                {source?.length > 0 && (
                  <>
                    <Tooltip title="Voltar para a partida">
                      <Button
                        type='primary'
                        icon={<ReloadOutlined />}
                        style={{ marginLeft: '.8rem' }}
                        disabled={!(moveLoading || moveContent) && Number(registry?.finished !== 1)}
                        onClick={() => dispatch(gameReadRequest(registry?.id))}
                      />
                    </Tooltip>

                    <Tooltip title="Voltar um lance">
                      <Button
                        type='primary'
                        icon={<ArrowLeftOutlined />}
                        onClick={() => backTip()}
                      >
                      </Button>
                    </Tooltip>

                    <Tooltip title="Iniciar sequencia de lances">
                      <Button
                        type='primary'

                        icon={<PlayCircleFilled />}
                        onClick={() => { }}
                      >
                      </Button>
                    </Tooltip>

                    <Tooltip title="Avançar um lance">
                      <Button
                        type='primary'
                        icon={<ArrowRightOutlined />}
                        onClick={() => forwardTip()}
                      >
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Col>
              <Col xs={24}>
                <Row gutter={16} style={{ overflow: 'hidden', overflowY: 'auto', marginTop: '1rem' }}>
                  {moveList?.map(item => {
                    if (item?.jsx) {
                      return (
                        <Col xs={12}>
                          <div className={`move-information ${item?.id === moveIndex ? 'move-selected' : ''}`} onClick={() => getMove(item?.id)}>
                            {item?.jsx}
                          </div>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Col>
            </Row>
          </MoveContainer>
        </Col>
      </Row>

      <ModalFinished isVisible={isVisibleFinished} setIsVisible={setIsVisibleFinished} gameId={registry?.id} />
    </Container >
  );
}
