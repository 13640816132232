import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { getQueryStringByName, isNullOrEmpty } from '~/helpers/util';

import { gameIndexRequest, gameCreateSuccess, gameCreateFailure, gameReadSuccess, gameReadFailure } from './actions';
import { gameContentReadFailure } from '~/store/modules/gameContent/actions';
import { gameTipCheckFailure } from '~/store/modules/gameTip/actions';

export function* onCreate() {
  try {

    let params = '?chess=1';

    const gameType = getQueryStringByName('gameType');

    if (!isNullOrEmpty(gameType)) params = `${params}&gameType=${gameType}`;

    const response = yield call(api.get, `/games/create/${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(gameCreateSuccess(data));
    yield put(gameIndexRequest());

    toast.success(result.message);

    window.location.href = `/game/${data.id}`;
  }
  catch (err) {
    toast.error(String(err));
    yield put(gameCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `games/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(gameReadSuccess(data));
    yield put(gameContentReadFailure());
  }
  catch (err) {
    toast.error(String(err));
    yield put(gameReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { id, piece, placeFrom, placeTo, token } = payload;

    if (token) api.defaults.headers.Authorization = `Bearer ${token}`;

    const response = yield call(api.get, `/games/${id}/piece-move/?piece=${piece}&placeFrom=${placeFrom}&placeTo=${placeTo}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(gameReadSuccess(data));
    yield put(gameContentReadFailure());
    yield put(gameTipCheckFailure());
  }
  catch (err) {
    toast.error(String(err));
    yield put(gameReadFailure());
  }
}

export default all([
  takeLatest('@games/CREATE_REQUEST', onCreate),
  takeLatest('@games/READ_REQUEST', onRead),
  takeLatest('@games/UPDATE_REQUEST', onUpdate)
]);
