import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Checkbox } from 'antd';

import history from '~/services/history';

import { signInRequest } from '~/store/modules/auth/actions';

import { GoogleOutlined } from '@ant-design/icons';
import { Container, Content, Header, Body, FormItem, Button } from './styles';

import piece from '~/assets/images/9.png';

export default function SignIn() {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.auth.loading);

  const [form] = Form.useForm();

  function onFinish(values) {
    const { email, password } = values;
    dispatch(signInRequest(email, password));
  }

  return (
    <Container>
      <Header>
        <Content>
          <div className='logo' onClick={() => history.push('/')}>
            <img src={piece} />
            <span>WSChess</span>
          </div>

          <Link to="/register">Criar conta</Link>
        </Content>
      </Header>

      <Body>
        <Content className='content'>
          <Row gutter={24}>
            <Col flex='auto' xs={0} md={12} lg={16} className='column info'>
              <h2>Bem vindo ao WebStation Chess!</h2>

              <p>Criamos este ambiente exatamente para jogadores que querem desenvolver suas habilidades, quem quer
                aprender e aqueles que querem se divertir.</p>
              <p>Faça login ou crie uma conta para poder jogar.</p>
            </Col>

            <Col xs={24} md={12} lg={8} className='column'>
              <div class="form-container">
                <Form layout="vertical"
                  form={form}
                  onFinish={onFinish}
                >
                  <FormItem
                    name="email"
                    label="E-mail"
                    rules={[{ required: true, message: 'E-mail é obrigatório' }]}
                  >
                    <Input type='email' />
                  </FormItem>

                  <FormItem
                    name="password"
                    label="Senha"
                    rules={[{ required: true, message: 'Senha é obrigatória' }]}
                  >
                    <Input type='password' />
                  </FormItem>

                  <FormItem name="rememberme">
                    <Checkbox>Manter conectado</Checkbox>
                  </FormItem>

                  <Row>
                    <Col xs={12} style={{ paddingRight: '0.4rem' }}>
                      <Button htmlType='button'>Logar com <GoogleOutlined /></Button>
                    </Col>
                    <Col xs={12} style={{ paddingLeft: '0.4rem' }}>
                      <Button htmlType='submit' type='primary' loading={loading}>Login</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Content>
      </Body>
    </Container >
  );
}
