import styled from 'styled-components';
import { Col, Space } from 'antd';
import { MenuOutlined, LogoutOutlined } from '@ant-design/icons';

export const Container = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  min-height: 64px;
  flex-direction: row;
  align-items: center;
  background-color: var(--header-background);
  z-index: 666;
  padding: 15px 20px;
`;

export const Content = styled(Space)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  h1 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }

  img {
    height: 70px;
  }
`;

export const UserInfo = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
`;

export const UserName = styled.span`
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
`;

export const UserPerfil = styled.span`
  color: rgba(255, 255, 255, 0.65);
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;
`;

export const MenuIcon = styled(MenuOutlined)`
  color: var(--secondary-text);
  cursor: pointer;
  user-select: none;
  font-size: 20px;
`;

export const LogoffIcon = styled(LogoutOutlined)`
  color: var(--secondary-text);
  cursor: pointer;
  user-select: none;
  font-size: 20px;
`;
