import produce from 'immer';
import moment from 'moment';

const INITIAL_STATE = {
  list: [],
  moves: [],
  ping: { count: 0, unix: moment().unix(), delay: 1 },
  loading: false,
  error: false
};

export default function gameTip(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@gameTips/INDEX_REQUEST':
      return produce(state, draft => {
        draft.line = -1;
      });

    case '@gameTips/INDEX_SUCCESS':
      return produce(state, draft => {
        const unix = state.ping.unix;
        draft.ping = action.payload.data;
        draft.ping.delay = draft.ping.unix - unix;
      });

    case '@gameTips/CHECK_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
        draft.list = [];
      });

    case '@gameTips/CHECK_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@gameTips/CHECK_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.list = [];
      });

    case '@gameTips/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@gameTips/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.moves = action.payload.data;
        console.log({ moves: draft.moves });
      });

    case '@gameTips/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    default:
      return state;
  }
}
