import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  roomIndexRequest, roomIndexSuccess, roomIndexFailure, roomCreateSuccess, roomCreateFailure, roomReadSuccess,
  roomReadFailure, roomUpdateSuccess, roomUpdateFailure, roomDeleteSuccess, roomDeleteFailure
} from './actions';

export function* onIndex() {
  try {
    const response = yield call(api.get, `rooms`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    yield put(roomIndexSuccess(result.data));
  }
  catch (err) {
    yield put(roomIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const { userId } = payload

    const formData = new FormData();

    const response = yield call(api.post, `/rooms/${userId}`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    yield put(roomCreateSuccess(result.data));
    yield put(roomIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(roomCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { roomId, userId } = payload

    const response = yield call(api.get, `/rooms/${roomId}/${userId}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    yield put(roomReadSuccess(result.data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(roomReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { roomId, userId } = payload

    const response = yield call(api.put, `/rooms/${roomId}/${userId}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    yield put(roomUpdateSuccess(result.data));
    yield put(roomIndexRequest());
  }
  catch (err) {
    toast.error(String(err));
    yield put(roomUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { roomId, userId } = payload

    const response = yield call(api.delete, `/rooms/${roomId}/${userId}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    yield put(roomDeleteSuccess(undefined));
    yield put(roomIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    toast.error(String(err));
    yield put(roomDeleteFailure());
  }
}

export default all([
  takeLatest('@rooms/INDEX_REQUEST', onIndex),
  takeLatest('@rooms/CREATE_REQUEST', onCreate),
  takeLatest('@rooms/READ_REQUEST', onRead),
  takeLatest('@rooms/UPDATE_REQUEST', onUpdate),
  takeLatest('@rooms/DELETE_REQUEST', onDelete)
]);
