import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';

import Home from '~/pages/Home';
import Game from '~/pages/Game';
import Room from '~/pages/Room';
import Friend from '~/pages/Friend';
import Stats from '~/pages/Stats';

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" exact component={SignIn} />
      <Route path="/register" exact component={SignUp} />

      <Route path="/" exact component={Home} isPrivate />
      <Route path="/game" exact component={Game} isPrivate />
      <Route path="/game/:id/:black?" exact component={Game} isPrivate />
      <Route path="/games" exact component={Room} isPrivate />
      <Route path="/friends" exact component={Friend} isPrivate />
      <Route path="/stats" exact component={Stats} isPrivate />

      <Redirect to="/" />
    </Switch>
  );
}
