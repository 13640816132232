import styled from 'styled-components';
import { Form, Button as _Button } from 'antd';

import bgLogin from '~/assets/images/bg_login.svg';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Baloo Tammudu 2', cursive;
  background-image: -webkit-gradient(linear,right top, left top,from(rgba(0,153,170,.18)),to(transparent));
  background-image: linear-gradient(270deg,rgba(0,153,170,.18),transparent);
  background-color: #86B1FD;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  height: 100%;
  padding: 0.5rem 0.9rem 0 0.9rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.25rem;
  background-image: -webkit-gradient(linear,right top, left top,from(rgba(0,153,170,.18)),to(transparent));
  background-image: linear-gradient(270deg,rgba(0,153,170,.18),transparent);
  background-color: #86B1FD;
  -webkit-box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;

  .logo {
    display: flex;
    margin-top: -0.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Fredoka One', cursive;
    user-select: none;
    cursor: pointer;

    img {
      max-height: 2.75rem;
    }

    span {
      margin-left: -2px;
      color: #ededed;
      font-size: 1.5rem;
    }
  }

  a {
    font-size: 1rem;
    line-height: 1rem;
    color: #eee;

    &:hover {
      color: #ddd;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 4.25rem);
  max-height: 100%;
  background: url(${bgLogin}) no-repeat center bottom fixed;
  background-size: cover;

  .content {
    align-items: flex-start;
    padding: 1.5rem;
    padding-top: 4.25rem;
  }

  .column {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .info {
    h2 {
      font-size: 1.75rem!important;
      font-weight: 500!important;
      color: #eee;
    }

    p {
      font-size: 1.1875rem;
      font-weight: 300!important;
      color: #eee;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    background-color: #f7f9fa;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.08);
    border-radius: 10px;
    padding: 1.5rem!important;
    -webkit-box-shadow: 0 0 13px 0 rgb(74 53 107 / 8%);
    box-shadow: 0 0 13px 0 rgb(74 53 107 / 8%);
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 1rem;

  .ant-form-item-label {
    padding: 0;
  }

  .ant-form-item-explain {
    margin-top: 0.3rem;
    margin-bottom: -0.6rem;
  }
`;

export const Button = styled(_Button)`
  width: 100%;
`;
