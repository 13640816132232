import produce from 'immer';
import { isNullOrEmpty } from '~/helpers/util';

const INITIAL_STATE = {
  profile: null,
  list: [],
  registry: {},
  game: [],
  piecesOut: [],
  lastMove: {},
  loading: false,
  error: false,
  pointBlack: 0,
  pointWhite: 0
};

export default function game(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@games/INDEX_REQUEST':
    case '@games/UPDATE_REQUEST':
    case '@games/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@games/READ_REQUEST':
    case '@games/CREATE_REQUEST':
    case '@games/UPDATE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@games/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@games/INDEX_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@games/READ_SUCCESS':
    case '@games/CREATE_SUCCESS':
    case '@games/UPDATE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
        draft.registry = action.payload.data;

        const content = draft.registry.content;
        const table = JSON.parse(content);

        draft.lastMove = draft.registry.LastMove;
        draft.pointWhite = isNullOrEmpty(table?.pointWhite) ? 0 : Number(table.pointWhite);
        draft.pointBlack = isNullOrEmpty(table?.pointBlack) ? 0 : Number(table.pointBlack);

        draft.game = table.content.map(e => {
          var image = require(`~/assets/images/0.png`);
          try {
            image = require(`~/assets/images/${e.piece}.png`);
          }
          catch (err) {
            //
          }

          return { ...e, image: image, type: e.piece % 2 === 1 ? 0 : 1 };
        });
        draft.piecesOut = table.pieceAte.map(e => {
          var image = require(`~/assets/images/0.png`);
          try {
            image = require(`~/assets/images/${e.piece}.png`);
          }
          catch (err) {
            //
          }

          return { ...e, image: image, type: e.piece % 2 === 1 ? 0 : 1 };
        });
      });

    case '@games/INDEX_FAILURE':
    case '@games/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case '@games/READ_FAILURE':
    case '@games/CREATE_FAILURE':
    case '@games/UPDATE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    default:
      return state;
  }
}
