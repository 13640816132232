import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { gameContentReadSuccess, gameContentReadFailure } from './actions';

export function* onRead({ payload }) {
  try {
    const { gameId, tipId } = payload;

    const response = yield call(api.get, `/games/${gameId}/content/${tipId}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    //console.table(result.data?.content);

    yield put(gameContentReadSuccess(result.data));
  }
  catch (err) {
    toast.error(String(err));
    yield put(gameContentReadFailure());
  }
}

export default all([
  takeLatest('@gameContents/READ_REQUEST', onRead),
]);
