import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Tooltip } from 'antd';

import history from '~/services/history';
import { isNullOrEmpty } from '~/helpers/util';

import { roomIndexRequest, roomCreateRequest, roomReadRequest, roomUpdateRequest, roomDeleteRequest } from '~/store/modules/room/actions';

import { PlusOutlined, PlayCircleOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Container, GameContainer } from './styles';

export default function Room(props) {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const games = useSelector(store => store.room.list);
  const room = useSelector(store => store.room.registry);
  const saving = useSelector(store => store.room.saving);

  useEffect(() => {
    setTimeout(() => {
      if (props?.match?.path === '/games') {
        dispatch(roomIndexRequest());
        if (!isNullOrEmpty(room?.id)) {
          console.log({ room });
          dispatch(roomReadRequest(room?.id, profile.id));
        }
      }
    }, 2500);
  }, [games]);

  useMemo(() => {
    if (!isNullOrEmpty(room?.gameId)) {
      const isBlack = room.Game.userBlackId === profile.id;

      if (!isBlack) window.location.href = `/game/${room.gameId}`;
      else window.location.href = `/game/${room.gameId}/1`;
    }
  }, [room]);

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={24} className='buttons'>
          <Tooltip title="Criar uma sala e aguardar oponente">
            <Button htmlType='button' type='primary' loading={saving} disabled={!isNullOrEmpty(room?.id)}
              onClick={() => dispatch(roomCreateRequest(profile.id))}>
              <PlusOutlined />Anfitrião
            </Button>
          </Tooltip>

          <Tooltip title="Entrar numa sala de um anfitrião">
            <Button htmlType='button' type='primary' loading={saving} disabled={!isNullOrEmpty(room?.id)} style={{ marginLeft: 10 }}>
              <PlayCircleOutlined />Visitante
            </Button>
          </Tooltip>
        </Col>

        {games.map(g => (
          <Col xs={24} sm={12} md={8} lg={6} key={g.id}>
            <GameContainer>
              <b>{g.User.name}</b>
              <span>Clássica</span>
              <div className='actions'>
                {profile?.id !== g.User.id ? (
                  <Tooltip title="Entra na partida">
                    <CheckCircleOutlined className='green' onClick={() => dispatch(roomUpdateRequest(g.id, profile.id))} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Excluir sala">
                    <DeleteOutlined className='red' style={{ marginLeft: 10 }} onClick={() => { if (window.confirm('Deseja realmente excluir a sala?')) dispatch(roomDeleteRequest(g.id, profile.id)) }} />
                  </Tooltip>
                )}
              </div>
            </GameContainer>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
