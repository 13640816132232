import produce from 'immer';
import { isNullOrEmpty } from '~/helpers/util';

const INITIAL_STATE = {
  content: undefined,
  piecesOut: undefined,
  pointWhite: undefined,
  pointBlack: undefined,
  loading: false,
  error: false
};

export default function gameContent(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@gameContents/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@gameContents/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
        const table = action.payload.data;
        draft.pointWhite = isNullOrEmpty(table?.pointWhite) ? 0 : Number(table.pointWhite);
        draft.pointBlack = isNullOrEmpty(table?.pointBlack) ? 0 : Number(table.pointBlack);

        draft.content = table.content.map(e => {
          var image = require(`~/assets/images/0.png`);
          try {
            image = require(`~/assets/images/${e.piece}.png`);
          }
          catch (err) {
            //
          }

          return { ...e, image: image, type: e.piece % 2 === 1 ? 0 : 1 };
        });
        draft.piecesOut = table.pieceAte.map(e => {
          var image = require(`~/assets/images/0.png`);
          try {
            image = require(`~/assets/images/${e.piece}.png`);
          }
          catch (err) {
            //
          }

          return { ...e, image: image, type: e.piece % 2 === 1 ? 0 : 1 };
        });
      });

    case '@gameContents/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.content = undefined;
      });

    default:
      return state;
  }
}
